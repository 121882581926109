<template>
  <section class="l login">
    <div>
      <h3 class="k-font h3 login-headline">Login</h3>
      <form @submit.prevent="login">
        <m-text-field
          id="email-field"
          type="email"
          required
          outlined
          use-native-validation
          class="kapp-fullwidth"
          v-model="email"
        >
          <m-floating-label for="email-field">
            E-Mail
          </m-floating-label>
        </m-text-field>
        <m-text-field
          id="password-field"
          type="password"
          required
          outlined
          use-native-validation
          class="kapp-fullwidth"
          v-model="password"
        >
          <m-floating-label for="password-field">
            Passwort
          </m-floating-label>
        </m-text-field>
        <div class="login-password-forgotten">
          <m-button href="#/kapp-panel/password-forgotten">Passwort vergessen?</m-button>
        </div>
        <m-button class="login-button" type="submit" unelevated>
          <span>Login</span>
        </m-button>
      </form>
      <div class="login-register-account">
        <div class="mdc-list-divider" />
        <m-button href="#/kapp-panel/register">Kein Account? Hier registrieren.</m-button>
      </div>
    </div>
    <div class="login-powered-by">
      <span>Powered by <a href="https://kommunale.app/" target="_blank">kommunale.app</a></span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Login',

  injectModels: ['View', 'Authentication', 'Router', 'Settings'],

  data() {
    return {
      email: '',
      password: '',
    };
  },

  watch: {
    'Authentication.token': {
      immediate: true,
      handler(val) {
        if (val) this.routeToHome();
      },
    },
  },

  mounted() {
    this.View.title = this.Settings.appSettings.mainTitle;
  },

  methods: {
    login() {
      setTimeout(() => {
        // TODO: Api Authentication Login
        this.Authentication.login();
      }, 500);
    },
    routeToHome() {
      this.Router.push('/kapp-panel/home');
    },
  },
};
</script>
