<template>
  <section class="l home">
    <div class="kapp-row">
      <div class="kapp-col-xs-12">
        <!-- TODO: get current survey from API -->
        <widget type="survey" background="https://img.nwzonline.de/rf/image_online/NWZ_CMS/NWZ/2011-2013/Produktion/2013/05/22/NORDENHAM/1/Bilder/NORDENHAM_1_f59a78a3-192c-42eb-882a-995a27db6a89--600x237.jpg">
          <template v-slot:title>Rathausvorplatz</template>
          <template v-slot:actionText>Zur Umfrage</template>
        </widget>
      </div>
      <div class="kapp-col-xs-12 margin-divider">
        <div class="mdc-list-divider" />
      </div>
      <div class="kapp-col-xs-4">
        <app-icon img="//placehold.it/192x192" label="Umfragen" />
      </div>
      <div class="kapp-col-xs-4">
        <app-icon img="//placehold.it/192x192" label="Mängelmelder" :badge="2" />
      </div>
      <div class="kapp-col-xs-4">
        <app-icon img="//placehold.it/192x192" label="Karte" />
      </div>
      <div class="kapp-col-xs-4">
        <app-icon img="//placehold.it/192x192" label="Einstellungen" />
      </div>
    </div>
    <m-button class="kapp-fullwidth home-logout" @click="logout">Logout</m-button>
  </section>
</template>

<script>
import AppIcon from '../../components/AppIcon/index.vue';
import Widget from '../../components/Widget/index.vue';

export default {
  name: 'Home',

  components: {
    AppIcon,
    Widget,
  },

  injectModels: ['View', 'Authentication', 'Router'],

  mounted() {
    this.View.title = 'Home';
    this.Router.initAuth(this.Authentication.token);
  },

  methods: {
    logout() {
      this.Authentication.logout().then(() => {
        this.Router.push('!');
      });
    },
  },
};
</script>
