import Vue from 'vue';
import VueStates from '@sum.cumo/vue-states';
import Ripple from 'vue-ripple-directive';

import App from './App.vue';
// import store from './services/store';
import './style.scss';
import './components/MaterialComponents';

Vue.use(VueStates);
Vue.directive('ripple', Ripple);

Vue.config.productionTip = false;

new Vue({
  // store,
  render: h => h(App),
}).$mount('#kommunale-app-root');
