<template>
  <div
    class="c widget"
    :class="{
      'no-background': !background,
      'survey': type === 'survey',
    }"
    :style="style"
    v-ripple
  >
    <div class="widget-content">
      <template v-if="type === 'survey'">
        <h3 class="widget-title k-font h5">
          <slot name="title" />
        </h3>
        <div class="widget-action">
          <slot name="actionText" />
          <m-icon :icon="icon" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widget',

  props: {
    type: {
      type: String,
      required: true,
    },
    background: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: String,
      default: 'chevron_right',
    },
  },

  computed: {
    style() {
      return {
        backgroundImage: this.background ? `url(${this.background})` : 'none',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      };
    },
  },
};
</script>
