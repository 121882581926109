<template>
  <section class="c slide-overlay">
    <div
      class="slide-overlay"
      :class="{'is-open': show}"
    >
      <header class="slide-overlay-header" :class="{'align-start': !closer}">
        <div class="k-headline">
          {{ title }}
        </div>
        <div v-if="closer" class="k-close">
           <m-fab
            mini
            :exited="!closer"
            @click="Router.push('#!')"
          >
            <m-icon slot="icon" icon="close" />
          </m-fab>
        </div>
      </header>
      <div class="slide-overlay-content">
        <slot />
      </div>
    </div>
    <a
      v-if="closer"
      href="#!"
      class="slide-overlay-backdrop"
      :class="{'is-open': show}"
    />
    <div v-else class="slide-overlay-backdrop" :class="{'is-open': show}" />
  </section>
</template>

<script>
export default {
  name: 'SlideOverlay',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    closer: {
      type: Boolean,
      default: true,
    },
  },

  injectModels: ['Router'],

  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          document.body.classList.add('kapp-body-overflow-hidden');
        } else {
          document.body.classList.remove('kapp-body-overflow-hidden');
        }
      },
    },
  },
};
</script>
