export default {
  data() {
    return {
      process: process.env.NODE_ENV,
      lastView: null,
      appSettings: null,
      darkMode: false,
    };
  },

  computed: {
    developmentMode() {
      return this.process === 'development';
    },
  },

  watch: {
    developmentMode: {
      immediate: true,
      handler(val) {
        if (val) document.body.classList.add('is-kapp-dev');
        else document.body.classList.remove('is-kapp-dev');
      },
    },
    darkMode(val) {
      if (val) document.body.classList.add('kapp-dark-mode');
      else document.body.classList.remove('kapp-dark-mode');
    },
  },

  created() {
    this.appSettings = JSON.parse(document.getElementById('kapp-settings').innerHTML);
    if (matchMedia('(prefers-color-scheme: dark)').matches) {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
  },
};
