import Vue from 'vue';
import TextField from 'material-components-vue/dist/text-field';
import FloatingLabel from 'material-components-vue/dist/floating-label';
import Icon from 'material-components-vue/dist/icon';
import Button from 'material-components-vue/dist/button';
import Fab from 'material-components-vue/dist/fab';
import IconButton from 'material-components-vue/dist/icon-button';
import List from 'material-components-vue/dist/list';
import LinearProgress from 'material-components-vue/dist/linear-progress';

Vue.use(TextField);
Vue.use(FloatingLabel);
Vue.use(Icon);
Vue.use(Button);
Vue.use(List);
Vue.use(LinearProgress);
Vue.use(IconButton);
Vue.use(Fab);
