<template>
  <div id="kommunale-app-root">
    <debug v-if="Settings.developmentMode" />
    <slide-overlay
      :show="Router.current.view === 'kapp-panel'"
      :title="View.title"
      :actions="View.actions"
    >
      <login v-if="Router.current.layout === 'login' || (Router.current.view === 'kapp-panel' && !Router.current.layout && !Authentication.token)" />
      <home v-if="Router.current.layout === 'home' || (Router.current.view === 'kapp-panel' && !Router.current.layout && Authentication.token)" />
    </slide-overlay>
    <m-fab
      class="kapp-activator"
      :exited="!Authentication.token"
      extended
      @click="Router.push(Router.last.view === 'kapp-panel' ? `/kapp-panel/${Router.last.layout}` : '/kapp-panel/home')">
      <m-icon icon="accessibility_new" slot="icon" />
      {{ Settings.appSettings.mainTitle }}
    </m-fab>
  </div>
</template>

<script>
// Components
import SlideOverlay from './components/SlideOverlay/index.vue';
// Layouts
import Debug from './layouts/Debug.vue';
import Login from './layouts/Login/index.vue';
import Home from './layouts/Home/index.vue';
// Models
import Authentication from './models/authentication';
import Settings from './models/settings';
import View from './models/view';
import Language from './models/language';
// Service Models
import Router from './services/router';

export default {
  name: 'app',

  components: {
    SlideOverlay,
    Debug,
    Login,
    Home,
  },

  models() {
    return {
      Settings,
      Router,
      View,
      Language,
      Authentication,
    };
  },
};
</script>
