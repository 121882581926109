export default {
  data() {
    return {
      hashValue: null,
      lastHashValue: null,
      current: {
        view: null,
        layout: null,
        params: null,
      },
      last: {
        view: null,
        layout: null,
        params: null,
      },
    };
  },

  computed: {
    currentRoute() {
      return this.current;
    },
  },

  created() {
    this.init();
  },

  destroyed() {
    this.remove();
  },

  methods: {
    init() {
      window.addEventListener('hashchange', () => this.onHashChange());
      this.onHashChange();
    },
    remove() {
      window.removeEventListener('hashchange', () => this.onHashChange());
      localStorage.setItem('kapp-last-view', JSON.stringify(this.current));
    },
    initAuth(token) {
      if (!token) this.push('/kapp-panel/login');
    },
    onHashChange() {
      this.lastHashValue = this.hashValue;
      this.last = this.current;
      // eslint-disable-next-line prefer-destructuring
      this.hashValue = window.location.href.split('#')[1] || null;
      if (this.hashValue) {
        this.current = {
          view: this.hashValue.split('/')[1],
          layout: this.hashValue.split('/')[2],
          params: this.hashValue.split('/')[3],
        };
      }
    },
    push(str) {
      window.location.hash = str;
    },
  },
};
