import api from '../api';

export default {
  data() {
    return {
      language: null,
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      const localStore = localStorage.getItem('kapp-language');
      if (localStore) this.language = localStore;
      api.language.get().then((resp) => {
        this.language = resp;
      });
    },
    update() {
      api.language.get().then((resp) => {
        this.language = resp;
      });
    },
  },
};
