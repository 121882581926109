<template>
  <button class="c app-icon">
    <img :src="img" :alt="label" class="app-icon-image">
    <div class="app-icon-label">
      {{ label }}
    </div>
    <div v-if="badge > 0" class="app-icon-badge">{{ badge }}</div>
  </button>
</template>

<script>
export default {
  name: 'AppIcon',

  props: {
    label: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    badge: {
      type: Number,
      default: 0,
    },
  },
};
</script>
