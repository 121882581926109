export default class LanguageApi {
  constructor() {
    this.language = {
      loginTitle: 'Meine Musterstadt',
    };
  }

  get() {
    return new Promise((res) => {
      res(this.language);
    });
  }
}
