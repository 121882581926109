// import api from '../api';

export default {
  data() {
    return {
      token: null,
    };
  },

  watch: {
    token(val) {
      if (val) document.body.classList.add('kapp-user-loggedin');
      else document.body.classList.remove('kapp-user-loggedin');
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      const storeToken = localStorage.getItem('kapp-token');
      if (storeToken) {
        this.checkToken(storeToken);
      }
    },
    checkToken(token) {
      // TODO: Api Call
      this.setToken(token);
    },
    setToken(token) {
      this.token = token;
      localStorage.setItem('kapp-token', token);
    },
    login() {
      // TODO: Api Call
      this.setToken('12837982347asddas978921-das87621e');
    },
    logout() {
      // TODO: Api Call
      return new Promise((res) => {
        this.token = null;
        localStorage.removeItem('kapp-token');
        res(null);
      });
    },
  },
};
